<template>
  <PartFilesViewer :partNumber="partNumber"></PartFilesViewer>
</template>

<script>
import { computed } from "vue-demi";
import PartFilesViewer from "../PartFilesViewer.vue";

export default {
  name: "PartFileAccordion",
  props: {
    node: {
      default: null,
    },
  },
  components: {
    PartFilesViewer,
  },
  setup(props) {
    const partNumber = computed(() => props.node?.part?.part_number ?? null);

    return {
      props,
      partNumber,
    };
  },
};
</script>
