<template>
  <v-container>
    <v-row>
      <LoadingContainer :loading="loading" class="text-center">
        <v-row>
          <v-col>
            <v-btn
              v-for="(file, i) in instanceFilesList"
              :key="file.id"
              @click="openOverlay(i)"
              :color="getFileInfo(file, 'id').color"
              icon
              x-large
            >
              <v-icon x-large>{{ getFileInfo(file, "id").icon }}</v-icon>
            </v-btn>
          </v-col>
        </v-row>
        <v-row v-if="!instanceFilesList.length">
          <v-col cols="12">
            <v-icon x-large>mdi-file-document-alert-outline</v-icon>
          </v-col>
          <v-col cols="12">
            <h3>No Files Found</h3>
          </v-col>
        </v-row>
        <v-btn color="blue" @click="refreshFiles" icon large>
          <v-icon large>mdi-refresh</v-icon>
        </v-btn>
      </LoadingContainer>
    </v-row>

    <v-overlay @click:outside="overlay = false" :value="overlay" v-if="overlay">
      <v-card flat tile style="width: 95vw; height: 95vh; text-align: right">
        <v-card-title>
          <v-row>
            <v-col>
              <v-btn text icon right color="red" @click="overlay = false">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-card-title>
        <LoadingContainer fluid :loading="loading">
          <v-window v-model="onboarding">
            <v-window-item v-for="file in instanceFiles" :key="file.data.id">
              <v-card>
                <v-row fill-height no-gutters class="text-center">
                  <v-col v-if="isFilePDF(file)">
                    <pdf
                      class="iframe pdf"
                      :src="getIframeSrc(file)"
                      :resize="false"
                      :annotation="true"
                      :scale.sync="scale"
                      :page="currentPage"
                      v-on:numpages="pageCount = $event"
                    >
                      <template slot="loading"> loading... </template>
                    </pdf>
                    <v-row no-gutters>
                      <v-col
                        cols="12"
                        class="d-flex justify-center align-center pt-1 pb-0"
                      >
                        <v-btn
                          icon
                          color="red"
                          @click="adjustScale(-0.1)"
                          class="mr-2"
                        >
                          <v-icon color="grey">mdi-magnify-minus</v-icon>
                        </v-btn>
                        <v-btn
                          icon
                          color="green"
                          @click="adjustScale(0.1)"
                          class="mr-2"
                        >
                          <v-icon color="grey">mdi-magnify-plus</v-icon>
                        </v-btn>
                        <template v-if="pageCount > 1">
                          <v-divider inset vertical />
                          <v-pagination
                            circle
                            v-model="currentPage"
                            :length="pageCount"
                          ></v-pagination>
                        </template>
                      </v-col>
                    </v-row>
                  </v-col>
                  <iframe v-else class="iframe" :src="getIframeSrc(file)" />
                </v-row>
              </v-card>
            </v-window-item>
          </v-window>
        </LoadingContainer>
        <v-card-actions class="justify-space-between pt-0">
          <v-btn text @click="prev">
            <v-icon>mdi-chevron-left</v-icon>
          </v-btn>
          <v-item-group v-model="onboarding" class="text-center" mandatory>
            <v-item
              v-for="file in formattedFiles"
              :key="file.data.id"
              v-slot="{ active, toggle }"
            >
              <v-btn
                :input-value="active"
                icon
                @click="toggle"
                :color="file.icon.color"
                x-large
              >
                <v-icon x-large>{{ file.icon.icon }}</v-icon>
              </v-btn>
            </v-item>
          </v-item-group>
          <v-btn text @click="next">
            <v-icon>mdi-chevron-right</v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-overlay>
  </v-container>
</template>

<script>
import { toRef, ref, watch, computed } from "vue-demi";
import { $filesHttp } from "@/utils";
import LoadingContainer from "@/components/LoadingContainer.vue";
import { get } from "lodash-es";
import pdf from "pdfvuer";
import { useStore } from "../store/app.pinia";

export default {
  name: "PartFilesViewer",
  props: {
    partNumber: {
      type: String,
      required: true,
    },
  },
  components: {
    LoadingContainer,
    pdf,
  },
  setup(props) {
    const appStore = useStore();
    // Get the partNumber from the props
    const partNumber = toRef(props, "partNumber");
    const overlay = ref(false);
    const instanceFilesList = ref([]);
    const instanceFiles = ref([]);
    const onboarding = ref(0);
    const loading = ref(false);
    const scale = ref("page-height");
    const cache = ref({});
    const pageCount = ref(0);
    const currentPage = ref(1);

    const sortFiles = (files, aPath = "data.id", bPath = "data.id") => {
      if (files.length) {
        // Files ending in .pdf should be first
        const firstExtension = ".pdf";
        files.sort((a, b) => {
          const idA = get(a, aPath, "")?.toLowerCase();
          const idB = get(b, bPath, "")?.toLowerCase();
          const idAEndsWith = idA.endsWith(firstExtension);
          const idBEndsWith = idB.endsWith(firstExtension);
          if (idAEndsWith && !idBEndsWith) return -1;
          if (!idAEndsWith && idBEndsWith) return 1;
          return 0;
        });
      }
      return files;
    };

    const updateFilesList = (forceRefresh = false) => {
      loading.value = true;
      // If we don't have a valid part number, skip the fetch
      if (
        !partNumber.value ||
        !typeof partNumber.value === "string" ||
        !partNumber.value.length
      ) {
        instanceFilesList.value = [];
        loading.value = false;
      }
      let fetchPath = `/file-storage?id=${partNumber.value}`;
      if (forceRefresh) {
        fetchPath += `&forceRefresh=true`;
      }
      // Fetch the files from the server
      return $filesHttp
        .get(fetchPath)
        .then((response) => {
          instanceFilesList.value = sortFiles(response.data, "id");
        })
        .catch((error) => {
          instanceFilesList.value = [];
          console.error("Error fetching files: ", error);
        })
        .finally(() => {
          loading.value = false;
        });
    };

    const setFiles = (files = []) => {
      instanceFiles.value = sortFiles(files);
      loading.value = false;
    };

    const updateFiles = () => {
      // If there's no files, don't do anything
      if (instanceFilesList.value.length === 0) return setFiles();
      // Set the loading state
      loading.value = true;
      // Get the file extensions that are available for this part
      const extensions = instanceFilesList.value
        .map((file) => file.name.split(".").pop() ?? null)
        .filter((ext) => !!ext);
      // If there's no extensions, don't do anything
      if (extensions.length === 0) return setFiles();
      // Build an array of paths to fetch
      const fetchPaths = extensions.map(
        (ext) => `/file-storage/${partNumber.value}.${ext}?urlOnly=true`
      );
      // Fetch the files from the server
      return Promise.all(
        fetchPaths.map((path) =>
          $filesHttp.get(path).catch((error) => {
            console.error("Error fetching files: ", error);
            return null;
          })
        )
      )
        .then((files) => {
          setFiles(files.filter((file) => !!file));
        })
        .finally(() => {
          loading.value = false;
        });
    };

    // Watch for part changes, and fetch the files list
    watch(partNumber, () => updateFilesList(), {
      immediate: true,
    });

    const refreshFiles = () => {
      // Refresh the files on the server
      updateFilesList(true);
    };

    const openOverlay = (key = 0) => {
      // Load the files
      updateFiles();
      // Set overlay key
      onboarding.value = key;
      // Set the pdf scale
      scale.value = "page-height";
      // Open the overlay
      overlay.value = true;
    };

    // TODO: are next and prev still needed?
    const next = () => {
      onboarding.value =
        onboarding.value + 1 === instanceFiles.value.length
          ? 0
          : onboarding.value + 1;
    };
    const prev = () => {
      onboarding.value =
        onboarding.value - 1 < 0
          ? instanceFiles.value.length - 1
          : onboarding.value - 1;
    };

    const getIframeSrc = (file) => {
      if (!file?.data) return null;
      const { data } = file;
      if (data?.uri) return data.uri;
      if (data?.url) return data.url;
      return null;
    };

    const getFileInfo = (file, idPath = "data.id") => {
      if (!get(file, idPath)) return null;
      const id = get(file, idPath)?.toLowerCase();
      if (id.includes("pdf")) {
        return {
          type: "pdf",
          icon: "mdi-file-pdf-box",
          color: "red",
        };
      }
      if (id.includes("html")) {
        return {
          type: "html",
          icon: "mdi-video-3d",
          color: "blue",
        };
      }
      return {
        type: "unknown",
        icon: "mdi-file",
        color: "grey",
      };
    };

    const isFilePDF = (file) => {
      const { type } = getFileInfo(file);
      if (type === "pdf") return true;
      return false;
    };

    const formattedFiles = computed(() => {
      return instanceFiles.value.map((file) => {
        return {
          ...file,
          icon: getFileInfo(file),
        };
      });
    });

    const adjustScale = (adjustment) => {
      // Get the new scale
      const newScale = scale.value + adjustment;
      // min/max scale sanity checks
      if (newScale < 0.1 || newScale > 3) return;
      // Set the new scale
      scale.value = newScale;
    };

    watch(
      () => overlay.value,
      (val) => {
        // If overlay is now open
        if (val) {
          // Close the navigation drawer
          if (appStore.drawer) {
            cache.value.drawer = appStore.drawer;
            appStore.drawer = false;
          }
        }
        // If the overlay is now closed
        if (!val) {
          // Restore the navigation drawer
          if (cache.value.drawer !== undefined) {
            appStore.drawer = cache.value.drawer;
            delete cache.value.drawer;
          }
        }
      }
    );

    // PDF Paging
    watch(pageCount, () => {
      currentPage.value = 1;
    });

    return {
      loading,
      instanceFilesList,
      instanceFiles,
      updateFiles,
      refreshFiles,
      overlay,
      openOverlay,
      onboarding,
      next,
      prev,
      getIframeSrc,
      getFileInfo,
      formattedFiles,
      isFilePDF,
      scale,
      adjustScale,
      pageCount,
      currentPage,
    };
  },
};
</script>

<style scoped>
.iframe {
  width: 100%;
  height: calc(100vh - 220px);
}

.iframe.pdf {
  overflow: auto;
  height: calc(100vh - 260px);
}
</style>
